exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-process-js": () => import("./../../../src/pages/process.js" /* webpackChunkName: "component---src-pages-process-js" */),
  "component---src-pages-requirements-js": () => import("./../../../src/pages/requirements.js" /* webpackChunkName: "component---src-pages-requirements-js" */),
  "component---src-pages-why-paraguay-js": () => import("./../../../src/pages/why-paraguay.js" /* webpackChunkName: "component---src-pages-why-paraguay-js" */),
  "component---src-templates-policy-template-js": () => import("./../../../src/templates/policy-template.js" /* webpackChunkName: "component---src-templates-policy-template-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/post-template.js" /* webpackChunkName: "component---src-templates-post-template-js" */)
}

